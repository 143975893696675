<template>
  <div class="main-wrap caseHome">
    <div class="caseHomebanner">
      <div class="bannercontent">
        <div class="subnav">
          <div class="top">学历案</div>
          <div class="submenu">
            <div class="sub" @click="toCourseware()">
              <img src="../../assets/images/case_01.png" alt="">
              <h2>学历案课件</h2>
            </div>
            <div class="sub" @click="toEvaluation()">
              <img src="../../assets/images/case_02.png" alt="">
              <h2>知识内容评测</h2>
            </div>
            <div class="sub" @click="toJournal()">
              <img src="../../assets/images/case_03.png" alt="">
              <h2>学历案日志</h2>
            </div>
            <div class="sub" @click="toWrongQuestion()">
              <img src="../../assets/images/case_04.png" alt="">
              <h2>错题本</h2>
            </div>
<!--            <div class="sub">-->
<!--              <img src="../assets/images/case_05.png" alt="">-->
<!--              <h2>强化训练</h2>-->
<!--            </div>-->
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="caTm">
        <div class="caTop">
          <div class="lf">
            <img src="../../assets/images/case_icon01.png" alt="">
            <h2>精品学历案</h2>
          </div>
          <div class="rg">
            <div class="subject-box">
               <span  v-for="(item,index) in SubjectList" :key="index" @click="onClickItemA(item.id)" class="sname" :class="{'onClickStyle':clickFlag == item.id}">{{item.name}} </span>
            </div>
          </div>
        </div>
        <div class="Slist" v-if="code==200">
          <div class="Sbooks" v-for="(sites,j) in BoutiqueXlaData.slice(0,6)" :key="j" @click="toCatalogue(sites)">
            <img :src="sites.cover" alt="">
            <div class="bookinf">
              <div class="bklf">
                <p>{{sites.year}}新版</p>
                <h2>{{sites.subject}}</h2>
              </div>
              <div class="bkrg">{{sites.name}}</div>
            </div>
          </div>
        </div>
        <div class="Slist" v-if="code==207">
          <div class="kong">
            <img src="../../assets/images/kong.png" alt="">
            <p>暂无数据</p>
          </div>
        </div>
      </div>
      <div class="cYTm">
        <div class="caTop">
          <div class="lf">
            <img src="../../assets/images/case_icon02.png" alt="">
            <h2>常用学历案</h2>
          </div>
          <div class="rg">
            <div class="subject-box">
              <span  v-for="(item,index) in SubjectList" :key="index" @click="onClickItemB(item.id)" class="sname" :class="{'onClickStyle':clickFlagB == item.id}">{{item.name}} </span>
            </div>
          </div>
        </div>
        <div class="Slist" v-if="codeB==200">
          <div class="Sbooks"  v-for="(sites,i) in listQueryCommUseXlaData.slice(0,6)" v-bind:key="i" @click="toCatalogue(sites)">
            <img :src="sites.cover" alt="">
            <div class="bookinf">
              <div class="bklf">
                <p>{{sites.year}}新版</p>
                <h2>{{sites.subject}}</h2>
              </div>
              <div class="bkrg">{{sites.name}}</div>
            </div>
          </div>
        </div>
        <div class="Slist" v-if="codeB==207">
          <div class="kong">
            <img src="../../assets/images/kong.png" alt="">
            <p>暂无数据</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapState } from 'vuex'
import {queryCartListObj} from '@/api/cart'
import {queryBoutiqueXlaObj,queryCommUseXlaObj,findScreenSubjectObj,getStuByUserNameObj,findGradeObj} from '@/api/caseHome'   //路径
export default {
  name: '',
  data() {
    return {
      code:null,
      codeB:null,
      subjectId:null,
      clickFlag:null,
      clickFlagB:null,
      BoutiqueXlaData:[],
      subjectList: [],
      queryBoutiqueXlaData:[],//精品学历案
      listQueryCommUseXlaData:[],//常用学历案
      cur: 0,//默认选中第一个tab
    }
  },
  created() {
    // 在页面加载时读取sessionStorage
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))))
    }
    // 在页面刷新时将store保存到sessionStorage里
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state))
    })
    this.stu = this.$route.query.stu;
    this.listQueryBoutiqueXla('null')
    this.listQueryCommUseXla()
    this.findScreenSubject()
    this.getStuByUser()
  },
  mounted() {
    // 从登陆页面过来的才调用获取购物车方法
    let params = this.$route.stu;
    // params 为true且值为login
    if (this.stu == 1) {
      this.queryCartList();
    }
  },
  methods: {
    // 查询个人信息
    getStuByUser() {
      getStuByUserNameObj().then((res) => { // eslint-disable-line no-unused-vars
        if (res.code == 200) {
          //this.StuByUser = res.data
          this.$store.commit('saveStuByUser', res.data)
        }
      })

    },
    findScreenGrade() {
      findGradeObj().then((res) => { // eslint-disable-line no-unused-vars
        if (res.code == 200) {
          this.$store.commit('saveStuByUser', res.data)
          this.findScreenGradeData = res.data
          console.log(res)
        }
      })

    },
    // 查询购物车列表
    queryCartList() {
      let params = {}
      queryCartListObj(params).then((res) => { // eslint-disable-line no-unused-vars
        this.CartListData = res.data
        console.log(res.data.length, '购物车的数量')
        this.$store.commit('saveCartCount', res.data.length)
      })
    },
    // 查询筛选的学科
    findScreenSubject() {
      findScreenSubjectObj().then((res) => { // eslint-disable-line no-unused-vars
        if (res.code == 200) {
          //this.StuByUser = res.data
          this.$store.commit('saveSubjectList', res.data)
        }
      })
    },
    //精品学历案
    listQueryBoutiqueXla(subjectId) {
      let params = {
        subjectId:subjectId
      }
      queryBoutiqueXlaObj(params).then((res) => { // eslint-disable-line no-unused-vars
        this.code = res.code
        if (res.code==200) {
          this.BoutiqueXlaData = res.data
        } else {
          this.BoutiqueXlaData = []
        }
      })
    },
    //常用学历案
    listQueryCommUseXla(subjectId) {
      let params = {
        subjectId:subjectId
      }
      queryCommUseXlaObj(params).then((res) => { // eslint-disable-line no-unused-vars
        this.codeB = res.code
        if (res.code==200) {
          this.listQueryCommUseXlaData = res.data
        } else {
          this.listQueryCommUseXlaData = []
        }
      })
    },
    // 跳转到学历案图书列表
    toCourseware() {
      this.$router.push({
        path: "/mainb/courseware",
        query: {
          EducationCase:1,
          clickFlag:0,
          stu:this.stu
        }
      });
    },
    // 跳转到学历案日志
    toJournal() {
      this.$router.push({
        path: "/mainb/journal",
        query: {
          EducationCase: 1,
          clickFlag: 1,
          stu:this.stu
        }
      });
    },
    // 跳转到学历案日志
    toEvaluation() {
      this.$router.push({
        path: "/mainb/evaluation",
        query: {
          EducationCase: 1,
          clickFlag: 2,
          stu:this.stu
        }
      });
    },
    // 跳转到学历案日志
    toWrongQuestion() {
      this.$router.push({
        path: "/mainb/wrongQuestion",
        query: {
          EducationCase: 1,
          clickFlag: 3,
          stu:this.stu
        }
      });
    },

    // 跳转到学历案图书目录
    toCatalogue(sites) {
      this.$router.push({
        path: "/mainb/catalogue",
        query: {
          EducationCase:1,
          clickFlag: 0,
          id:sites.id,
          stu:this.stu
        }
      });
    },
    //点击改变文字颜色
    onClickItemA(item){
      this.clickFlag = item
      this.listQueryBoutiqueXla(item)
    },

    onClickItemB(item){
      this.clickFlagB = item;
      this.listQueryCommUseXla(item)
    },
  },
  computed:{
    ...mapState([
      'SubjectList',
    ]),
  },
  components: {


    //注册图标组件名称
  }
}
</script>
<style lang="scss" scoped>
.main-wrap {
  background: #fff;
  .caseHomebanner {
    width: 100%;
    height: 500px;
    background: url("../../assets/images/caseHomebanner.png") center center no-repeat;
    background-size: cover;

    .bannercontent {
      width: 1200px;
      height: 500px;
      margin: auto;

      .subnav {
        width: 600px;
        position: absolute;
        margin: 90px auto auto 700px;

        .top {
          height: 40px;
          color: #F77134;
          font-weight: bold;
          border-bottom: 1px solid #ddd;
          margin-bottom: 10px;
        }
        .submenu {
          width: 100%;
          height: 70px;
          font-size:14px ;
          display: inline-flex;
          .sub {
            width: 110px;
            text-align: center;
            cursor:pointer;
            img {
              width: 54px;
              height: 54px;
              margin: auto;
            }
            h2 {

            }
          }
        }
      }
    }
  }

  .content {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.16);
    margin: 0px 0px 20px 0px;
    justify-content: space-between;
    width: 90%;
    border-radius: 0px;
    .caTm {
      width: 100%;
      margin-top: 10px;
      .caTop {
        width: 100%;
        display: flex;
        position: relative;
        .lf{
          width: 40%;
          display: flex;
          justify-content: flex-start;
          img{height:22px;margin-right: 10px; margin-top: 8px}
          h2 {line-height:40px;border-bottom:2px solid #AF69F3;font-weight:bold;color:#AF69F3;font-size: 16px}
        }
        .rg{
          width: 60%;
          box-sizing: border-box;
          display: flex;
          justify-content: flex-end;
          .subject-box {
            width: 800px;
            line-height: 40px;
            justify-content: flex-end;
            text-align: right;
            .sname {
              color: #333;
              background: #FFF;
              text-align: center;
              border: 1px solid #FFF;
              opacity: 1;
              padding: 4px 10px;
              border-radius: 15px;
            }
            .onClickStyle {
              color: #FF6820;
              background: #FFEFE8;
              border: 1px solid #FF6820;
              opacity: 1;
            }
          }
        }
      }
      .Slist {
        width: 100%;
        margin-top: 24px;
        display: flex;
        .kong {
          width: 400px;
          margin: auto;
          text-align: center;
          img {
            margin: auto;
            height: 200px;
          }
          p {
            height: 30px;
            line-height: 30px;
            color: #c6c6c6;
            span {
              color: #238bff;
              cursor:pointer
            }
          }
        }
        .Sbooks {
          width: 258px;
          height: 268px;
          background: url("../../assets/images/baj.png") no-repeat;
          background-size: cover;
          float: left;
          text-align: center;
          margin-right: 26px;
          padding-top: 16px;
          box-sizing: border-box;
          img {
            width: 110px;
            height: 160px;
            margin: auto;
          }
          .bookinf {
            width: 100%;
            display: flex;
            padding-top: 26px;
            .bklf {
              width: 80px;
              margin-right: 20px;
              p {line-height: 18px;color: #fff}
              h2 {margin-top:5px;font-weight: bold;font-size: 18px;color: #FF5757}
            }
            .bkrg {
              width: 150px;
              padding-top: 18px;
              padding-right: 10px;
              line-height: 18px;
              box-sizing: border-box;
              color: #fff; text-overflow: -o-ellipsis-lastline;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;
            }
          }
        }

        .Sbooks:nth-child(4n+4) {
          border-right: 0;
          margin-right: 27px;
          margin-bottom: 20px;
        }

        .Sbooks:nth-child(n+6) {
          margin-right: 0px;
          border-bottom: 0
        }
        //
        //.Sbooks:hover {
        //  box-shadow: 0px 0px 12px 0px rgba(163, 163, 163, 0.35);
        //}
      }
    }
    .cYTm {
      width: 100%;
      margin-top: 10px;
      padding-bottom: 50px;
      .caTop {
        width: 100%;
        display: flex;
        position: relative;
        .lf{
          width: 40%;
          display: flex;
          justify-content: flex-start;
          img{height:22px;margin-right: 10px; margin-top: 8px}
          h2 {line-height:40px;border-bottom:2px solid #FF6820;font-weight:bold;color:#FF6820;font-size: 16px}
        }
        .rg{
          width: 60%;
          box-sizing: border-box;
          display: flex;
          justify-content: flex-end;
          .subject-box {
            width: 800px;
            line-height: 40px;
            justify-content: flex-end;
            text-align: right;
            .sname {
              color: #333;
              background: #FFF;
              text-align: center;
              border: 1px solid #FFF;
              opacity: 1;
              padding: 4px 10px;
              border-radius: 15px;
            }
            .onClickStyle {
              color: #FF6820;
              background: #FFEFE8;
              border: 1px solid #FF6820;
              opacity: 1;
            }
          }
        }
      }
      .Slist {
        width: 100%;
        margin-top: 24px;
        margin-bottom: 10px;
        display: flex;
        .Sbooks {
          width: 258px;
          height: 268px;
          background: url("../../assets/images/baj.png") no-repeat;
          background-size: cover;
          float: left;
          text-align: center;
          margin-right: 26px;
          padding-top: 16px;
          box-sizing: border-box;
          img {
            height: 160px;
            margin: auto;
          }
          .bookinf {
            width: 100%;
            display: flex;
            padding-top: 26px;
            .bklf {
              width: 80px;
              margin-right: 20px;
              p {line-height: 18px;color: #fff}
              h2 {margin-top:5px;font-weight: bold;font-size: 18px;color: #FF5757}
            }
            .bkrg {
              width: 150px;
              padding-top: 18px;
              padding-right: 10px;
              line-height: 18px;
              box-sizing: border-box;
              color: #fff; text-overflow: -o-ellipsis-lastline;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;
            }
          }
        }

        .Sbooks:nth-child(4n+4) {
          border-right: 0;
          margin-right: 27px;
          margin-bottom: 20px;
        }

        .Sbooks:nth-child(n+6) {
          margin-right: 0px;
          border-bottom: 0
        }
        //
        //.Sbooks:hover {
        //  box-shadow: 0px 0px 12px 0px rgba(163, 163, 163, 0.35);
        //}
      }
    }


    .logo-wrap {
      width: 640px;
      position: relative;
      margin-top: 80px;

      img {
        width: 300px;
        float: right;
        margin-top: 50px;
      }
    }

    .centreContent {
      width: 520px;
      padding: 40px 40px;
      box-sizing: border-box;
      /*height: 540px;*/
      float: right;
      border-radius: 20px;
      margin-right: -100px;

      background: #FFFFFF;
      border: 1px solid #FFFFFF;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
      opacity: 0.9;

      .tabs {
        width: 100%;
        padding: 0;
        text-align: center;

        .active1 {
          border-radius: 50px;
          border: 1px solid #F77134;
          background: #FFFCB2;
          color: #ff6820;
        }

        .li-tab {
          width: 60px;
          height: 60px;
          border-radius: 50px;
          border: 1px solid #bbb;
          margin: 0px 25px 0 25px;
          display: inline-block;
          text-align: center;
          font-size: 14px;
          cursor: pointer;

          img {
            width: 60px;
            height: 60px;
          }

          .t1 {
            text-align: center;
          }
        }

        .divTab {
          margin-top: 30px;

          ::v-deep .el-input__inner {
            border-top: 0;
            border-left: 0;
            border-right: 0;
            border-radius: 0;
            padding: 0 54px;
          }

          .form-item {
            position: relative;

            .user {
              color: #666;
              font-size: 24px;
              position: absolute;
              z-index: 9;
              margin-top: 10px;
              margin-left: 10px;
              padding-right: 10px;
              border-right: 1px solid #d5dee9;
            }

            .lock {
              color: #666;
              font-size: 24px;
              position: absolute;
              z-index: 9;
              margin-top: 10px;
              margin-left: 10px;
              padding-right: 10px;
              border-right: 1px solid #d5dee9;
            }

            .ScaleToOriginal {
              color: #666;
              font-size: 24px;
              position: absolute;
              z-index: 9;
              margin-top: 10px;
              margin-left: 10px;
              padding-right: 10px;
              border-right: 1px solid #d5dee9;
            }

            .code-img {
              width: 84px;
              height: 40px;
              position: absolute;
              top: 0px;
              right: 0;
              cursor: pointer
            }
          }

        }
      }
    }
  }

  .copyright {
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -130px;
    line-height: 50px;
    text-align: center;
    font-size: 14px;
    margin-bottom: 50px;
    z-index: 99;

    .cop1 {
      height: 30px;
      line-height: 30px;
      color: #fff;

      a {
        color: #fff;
      }
    }
  }

}
</style>
